/* remove default caret for ie */
.select-form::-ms-expand {
    display: none;
}

.reactable-filter-input {
    color: black;

    &:-webkit-input-placeholder:before {
        color: #666;
        content: 'Filter users';
    }
}

.subject-optional-note a,
.public-DraftStyleDefault-block a {
    color: #324cdd;

    &:hover {
        color: #324cdd;
        text-decoration: underline;
    }
}
