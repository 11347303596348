.quiz-slide {
    padding: 40px;

    @media (max-width: 992px) {
        padding-left: 48px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    img {
        max-width: 100%;
    }

    &.center {
        margin: 0 auto;

        button {
            margin: 10px 0;
            align-self: center;
        }

        @media (max-width: 992px) {
            text-align: center;
        }
    }

    .row {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            flex-direction: column;
            min-height: 200px;
            display: block;
        }
    }

    p {
        font-size: 16px;
        line-height: 150%;
        color: rgba($heavymetal, 0.6);
        max-width: 700px;
    }

    strong {
        color: $heavymetal;
    }

    .actions {
        margin-top: 20px;

        button {
            margin-left: 0;
        }
    }

    .quiz-results {
        padding: 40px 40px 0 40px;
        max-width: 760px;
        margin: 30px 0 0 0;

        p {
            font-size: 16px;
            color: rgba($heavymetal, 0.6);
        }

        button {
            margin-right: 20px;
            font-weight: 700;
        }

        .scoreHeading {
            text-align: left;
            @media (max-width: 992px) {
                text-align: center;
            }
        }
    }

    .btn-text {
        text-transform: uppercase;
        padding: 0.5rem;
        font-size: 13px;
        letter-spacing: 0.04em;
        font-weight: 700;
        color: rgba($heavymetal, 0.6);
        cursor: pointer;
        text-decoration: none;

        &:hover {
            color: rgba($heavymetal, 0.8);
        }
    }
}

.assessment-review {
    @media (max-width: 992px) {
        p {
            font-size: 12px;
            color: $heavymetal;
        }
    }
}

.quiz-results-table {
    max-width: 700px;

    @media (max-width: 992px) {
        max-width: 90%;
    }
}

.progress-semicircle {
    margin: 10px auto;
    position: relative;

    &.small {
        width: 66px;
        height: 50px;
        font-size: 16px;
        font-weight: 600;
        margin-top: 4px;
        margin-bottom: -4px;
    }

    &.tiny {
        width: 50px;
        height: 34px;
        font-size: 16px;
        font-weight: 600;
        margin-top: 4px;
        margin-bottom: -4px;
    }

    &.with-stars {
        padding-top: 30px;

        &.green {
            &:after {
                background: url('/static/img/stars-green.svg') no-repeat top
                    center;
            }
        }

        &.yellow {
            &:after {
                background: url('/static/img/stars-yellow.svg') no-repeat top
                    center;
            }
        }

        &.red {
            &:after {
                background: url('/static/img/stars-red.svg') no-repeat top
                    center;
            }
        }

        &:after {
            position: absolute;
            content: '';
            background-size: 100% auto;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: -0px;
            right: 0;
        }
    }

    .text {
        text-align: center;

        .score {
            font-size: 40px;
            font-weight: 700;

            @media all and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                margin-top: 90px;
            }
        }

        .description {
            color: #8e8e8e;
            font-weight: 100;
            font-size: 14px;
            @media all and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                margin-top: -9px;
            }
        }

        &.small {
            margin-top: -4px;
            font-weight: 600;

            .score {
                font-size: 15px;
                font-weight: 800;
            }
        }
    }
}

.assessment {
    .assessment-header {
        background: white;
        height: 70px;
        box-shadow: 0px 4px 24px rgba(29, 30, 29, 0.08),
            0px 0px 2px rgba(0, 0, 0, 0.08);
        box-sizing: content-box;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 100;
        max-width: 1560px;
        width: 100%;
    }

    .assessment-body {
        display: flex;
        overflow: hidden;
        max-width: 1560px;
        width: 100%;
        height: 100%;
        padding: 20px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 40px;
        @media (max-width: 992px) {
            padding: 0 10px;
        }
    }

    .assessment-question {
        width: 100%;
        flex-direction: column;
        display: flex;
        max-width: 80%;
        margin: 0 auto;

        @media (max-width: 992px) {
            padding-bottom: 20px;
            max-width: 100%;
            margin: 0;
        }

        .password-tester-formcontrol {
            width: 120px;
            @media (max-width: 992px) {
                align-self: center;
            }
        }

        /* reset for MU margins */
        .MuiFormControlLabel-root {
            margin-left: unset;
            margin-right: unset;
        }

        .MuiTypography-root {
            margin-left: 10px;
            @media (max-width: 992px) {
                font-size: 14px;
            }
        }

        h5 {
            font-weight: 700;
            padding: 0;
            margin: 0;
            color: #878887;

            @media (max-width: 992px) {
                font-size: 14px;
                margin-bottom: 8px;
            }
        }

        p {
            font-size: 16px;
            color: $heavymetal;
            padding: 10px 0 10px 0;
        }

        .image-fullwidth {
            padding-bottom: 20px;

            img {
                width: 100%;
            }
        }

        // with answers
        .score-0 {
            .Mui-selected {
                background-color: $score-0;
                border-top: 0;
                border-bottom: 0;
            }
        }

        .score-25 {
            .Mui-selected {
                background-color: $score-25;
                border-top: 0;
                border-bottom: 0;
            }
        }

        .score-50 {
            .Mui-selected {
                background-color: $score-50;
                border-top: 0;
                border-bottom: 0;
            }
        }

        .score-75 {
            .Mui-selected {
                background-color: $score-75;
                border-top: 0;
                border-bottom: 0;
            }
        }

        .score-100 {
            .Mui-selected {
                background-color: $score-100;
                border-top: 0;
                border-bottom: 0;
            }
        }
    }

    .assessment-question-checkbox {
        max-width: 600px;
        margin: 20px auto;
    }

    .assessment-buttons {
        display: flex;
        flex-direction: row;
        padding: 10px 20px;
        width: 100%;
        max-width: 800px;
        justify-content: space-around;
        font-size: 14px;
    }

    .assessment-close {
        background: white;
        width: 20px;
        height: 20px;
        position: absolute;
        left: 20px;
        top: 16px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .assessment-block {
        padding: 12px;
        background: #ffffff;
        box-shadow: 0px 4px 8px rgba(29, 30, 29, 0.06);
        border-radius: 4px;
    }
}

.full-screen {
    height: 100%;
}

.image-zoomed {
    position: fixed;
    background: rgba($heavymetal, 0.9);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
        max-width: 95%;
    }

    &:after {
        content: '';
        cursor: pointer;
        position: absolute;
        background: black url('/static/img/icon-close.svg') no-repeat center
            center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        top: 10px;
        left: 10px;
    }
}

.assessment-presentation {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 60px;

    @media (max-width: 992px) {
        padding-top: 10px;
    }

    .password {
        background: rgba(29, 30, 29, 0.03);
        padding: 8px 20px;
        border-radius: 4px;
        font-weight: bold;
        font-size: 18px;
        display: flex;
        align-self: flex-start;
        margin: 3px 0 10px 0;
    }

    .image {
        text-align: center;
        @media (max-width: 992px) {
            // width: 40%;
            align-self: center;
            padding-bottom: 20px;
            img {
                width: 100%;
                align-self: center;
            }
        }
    }

    .email-footer {
        .attachment {
            width: 180px;
        }

        img {
            cursor: pointer;
        }
    }

    .details-tooltiptext {
        background: white;
        filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.15));
        border: 1px solid #cccccc;
        border-radius: 2px;
        color: black;
        width: 360px;
        opacity: 0;
    }

    .tooltip-from {
        display: flex;
        // position: static;
        width: 80%;
        border: 1px solid #cccccc;
        padding: 16px;
        background-color: white;
        box-shadow: 0px 0px 21px -4px rgba(0, 0, 0, 0.17);
        position: absolute;
        font-size: 12px;
        cursor: pointer;
        transition: ease 0.2s all;
        left: 70px;
        top: 36px;
        padding: 12px;

        @media (max-width: 992px) {
            box-shadow: none;
            border-radius: 6px;
            width: 100%;
            display: block;
            // background-color: pink !important;
            margin-top: 20px;
            position: static;
            margin-left: -50px;
            padding-bottom: 17px;
            box-sizing: content-box;
            overflow-wrap: break-word;
        }

        .left {
            @media (max-width: 922px) {
                // width: 10%!important;
                // padding-left: 0!important;
                // padding-right: 0!important;
            }
            padding-left: 20px;
            color: #999999;
            text-align: right;
        }

        .right {
            @media (max-width: 922px) {
                // width: 80%!important;
            }
            padding-left: 20px;
            // padding-left: 0px;
            text-align: left;
        }
    }

    .email-attachement-hover {
        position: absolute;
        left: 0;
        bottom: 0;
        font-size: 12px;
        background: #dfe1e6;
        padding: 2px 6px;
        z-index: 1000;
    }

    .email-attention {
        position: absolute;
        bottom: -60px;
        background: #e8eae9;
        padding: 10px 20px;
        font-size: 12px;
        border-radius: 10px;
        transition: ease 0.2s all;

        @media (max-width: 992px) {
            bottom: -50px;
        }
    }

    .link-tooltip {
        position: absolute;
        border: 1px solid #cccccc;
        padding: 6px;
        border-radius: 2px;
        background-color: white;
        box-shadow: 0px 0px 21px -4px rgba(0, 0, 0, 0.17);
        z-index: 1000;
    }
}

.question {
    max-width: 560px;
    width: 100%;
    flex-direction: column;
    display: flex;
    margin: 0 auto;

    @media (max-width: 992px) {
        max-width: initial;
        padding: 20px 10px !important;
    }

    p {
        font-size: 16px;
        color: rgba($heavymetal, 0.8);
        white-space: pre-line;
    }

    normal {
        font-style: normal;
        font-weight: 400;
        color: rgba(52, 53, 52, 0.8);
    }

    .disclaimer {
        font-size: 12px;
        margin-bottom: 0;
        margin-top: 32px;

        .disclaimer-icon {
            height: 22px;
            margin-right: 5px;
        }
    }

    .image {
        margin-top: 30px;
    }
}

h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 40px;
}

@keyframes meter-progress {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 50px 50px;
    }
}

.assessment-block-type-2 {
    background: #f9faf9;
    padding-top: 10px;

    @media (max-width: 992px) {
        width: 100%;
        align-self: center;
        padding: 20px;
    }
}

.assessment-carousel-progress {
    display: block;

    .step-follow  {
        background: url('/static/img/icon-step-following.svg') no-repeat center
            center;
        width: 10px;
        height: 10px;
        background: blue;
    }
}

.assessment-carousel {
    display: flex;
    max-width: 80%;
    margin: 0 auto;
    width: 100%;
    justify-content: space-between;
    padding: 40px 0 20px 0;
    @media (max-width: 992px) {
        padding: 20px 0;
        max-width: 90%;
    }
    @media (max-width: 599px) {
        padding: 10px 0px;
        max-width: 96%;
    }
}

.assessment-padding {
    padding: 100px 40px 0 40px;
    @media (max-width: 992px) {
        padding-top: 0px;
    }
}

.assessment-carousel-buttons {
    button {
        background: none;
        //border: 1px solid #1D1E1D;
        border: 1px solid #e3e3e3;
        border-radius: 50%;
        box-shadow: none;
        margin-left: 8px;

        &:focus {
            outline: none;
            box-shadow: none;
        }

        &.Mui-disabled {
            opacity: 0.2;
            background: none;
        }
    }
}

.progress-semicircle {
    width: 200px;
    background: url();

    svg {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            transform: rotate(-105deg);
            margin-top: -10px;
        }
    }
}

// fix for seperate buttons
.assessment-question {
    .image {
        margin: 0 auto;

        @media (max-width: 992px) {
            margin: 0;
            img {
                width: 100%;
            }
        }

        img {
            max-width: 100%;
        }

        &.clickable {
            cursor: pointer;
        }

        &.fullwidth {
            margin: 20px 0;

            img {
                width: 100%;
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

/* Material UI custom */
.assessment {
    .MuiTypography-root {
        font-family: 'Nunito Sans', sans-serif;
    }
}

.MuiTable-root {
    .MuiTableCell-head {
        color: rgba($heavymetal, 0.6);
        font-weight: 400;
        font-size: 14px;
    }

    .MuiTableBody-root {
        .MuiTableCell-root {
            font-size: 14px;
            color: $heavymetal;

            &:nth-child(1) {
                font-size: 16px;
                font-weight: 700;

                @media (max-width: 992px) {
                    font-size: 14px;

                    div {
                        font-size: 12px;
                        color: rgba($heavymetal, 0.6);
                        font-weight: 400;
                    }
                }
            }

            padding: 5px 16px;
        }
    }
}

.MuiSlider-thumb {
    position: relative;
    margin-left: 2px;
    margin-top: -2px;

    &:before {
        content: ' ';
        position: absolute;
        border: 3px solid $aspargus;
        z-index: 1;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        margin-top: -10px;
    }
}

.MuiSlider-root {
    color: $aspargus;
}

.MuiSlider-mark {
    margin-top: -13px;
    border: 3px solid #afafaf;
    z-index: 1;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin-left: -5px;

    &:before {
        content: ' ';
        position: absolute;
        border: 3px solid white;
        background: white;
        top: 0px;
        left: 0px;
        z-index: 1;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        transition: ease 0.2s all;
    }

    &:hover {
        border: 3px solid #bfbfbf;
    }
}

.MuiSlider-markActive {
    margin-top: -13px;
    border: 3px solid $aspargus;
    z-index: 1;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin-left: -4px;
    background: white;
    opacity: 1;

    &:before {
        content: ' ';
        position: absolute;
        border: 3px solid $aspargus;
        background: $aspargus;
        top: 5px;
        left: 5px;
        z-index: 1;
        width: 18px;
        height: 18px;
        border-radius: 50%;
    }
}

.MuiSlider-markLabel {
    margin-top: 10px;
    margin-left: 8px;
}

.MuiSlider-thumb {
    &.MuiSlider-active {
        box-shadow: 0 0 0 14px rgba($aspargus, 0.2);
    }
}

.MuiSlider-thumb.Mui-focusVisible,
.MuiSlider-thumb:hover {
    box-shadow: 0px -5px 0px 10px rgba(63, 181, 112, 0.16);
}

.MuiRadio-root {
    color: rgba(0, 0, 0, 0.34);
}

.MuiFormLabel-root {
    color: $heavymetal;
    opacity: 0.5;
    font-size: 14px;
    padding-bottom: 4px;
}

/* MUI Buttons general */
.MuiButtonBase-root {
    font-family: 'Nunito Sans', sans-serif !important;

    &.MuiButton-root {
        padding: 14px 50px;
        background: #ffffff;
        border-color: #cdcecd;
        color: #1d1e1d;
        transition: ease 0.2s all;
        font-size: 14px;
        box-shadow: 0px 4px 8px rgba(29, 30, 29, 0.06);
        border-radius: 4px;
        width: 100%;

        &:hover {
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08),
                0px 4px 24px rgba(29, 30, 29, 0.08);
            background: #ffffff;
        }

        &:active {
            opacity: 0.6;
        }
    }
}

// Checkboxes
.MuiFormControlLabel-root {
    &:hover {
        .MuiButtonBase-root {
            &.MuiCheckbox-root {
                background: #e8eae9;
                border-radius: 2px;
                padding: 2px;
                margin: 10px;
            }
        }
    }

    .MuiButtonBase-root {
        &.MuiCheckbox-root {
            padding: 2px;
            margin: 10px;

            &.Mui-checked {
                border-radius: 4px;
                padding: 0px;
                margin: 12px;
            }
        }
    }
}

.select-from-text {
    height: 40px;
    width: 240px;
    border: 1px solid #e3e3e3;
    color: #616263;
    position: relative;
    border-radius: 3px;
    background: lighten($snowdrift, 10);
    border-radius: 5px;
    margin: 10px 0;
    font-size: 0.6em;

    @media (max-width: 992px) {
        text-align: right;
    }

    .title {
        position: absolute;
        top: -20px;
        left: 10px;
    }

    &:after {
        content: '';
        background: url('/static/img/icon-arrow-down.png') no-repeat center
            center;
        padding: 12px 8px;
        position: absolute;
        right: 10px;
        top: 0;
        z-index: 1;
        text-align: center;
        width: 10%;
        height: 100%;
        pointer-events: none;
    }

    .select-form {
        height: 40px;
        width: 100%;
        padding: 5px 15px;
        background: none;
        border: 0;
        outline: none;
        font-size: 1.4em;
        -webkit-appearance: none; /* for webkit browsers */
        -moz-appearance: none; /* for firefox */
        appearance: none; /* for modern browsers */
    }
}

.MuiListItem-container {
    .MuiListItemIcon-root {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        min-width: 50px;
        @media (max-width: 992px) {
            min-width: 40px;
        }
    }
}

.previous-btn {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: $aspargus;
    letter-spacing: 4%;
    cursor: pointer;
}

.mobile-subquestion {
    color: rgba($heavymetal, 0.6);
    font-size: 10px;
    font-weight: 800;
    padding: 10px 0;
    width: 100%;
}

// Question answers
.wizard-content {
    scroll-behavior: smooth;

    .details-header {
        text-transform: uppercase;
    }
}

// Animations

.fade-in {
    animation: 0.5s ease-out 0s 1 fadeIn;
}

.fade-out {
    animation: 0.5s ease-out 0s 1 fadeOut;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateX(10px);
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: translateX(0px);
    }
    100% {
        transform: translateX(-10px);
        opacity: 0;
    }
}

.Modal {
    &.assessment-mobile {
        display: flex;
        flex-direction: column;
        max-width: 92%;
        min-width: unset;
        padding: 20px 30px;
        border-radius: 4px;
        box-shadow: 0px 0px 12px -4px rgba(0, 0, 0, 0.1);
        text-rendering: optimizeLegibility;
        outline: none;
        position: relative;
        top: initial;
        left: initial;
        right: initial;
        bottom: initial;
        background: white;
        text-align: center;

        p {
            color: $heavymetal;
            font-size: 13px;
            line-height: 20px;
        }
    }
}

.hoverable-image {
    width: 465px;
    height: 261px;
    position: relative;
    border-radius: 8px;
    margin-top: 30px;

    img {
        transition: ease-in-out 0.1s all;
        transform-origin: center center;
        cursor: pointer;
        opacity: 0.9;

        &:hover {
            opacity: 1;
            transform: scale(1.2, 1.2);
        }
    }

    @media (max-width: 992px) {
        transform: scale(0.7);
        align-self: center;
    }
}

.score-line {
    display: none;

    @media (max-width: 992px) {
        display: flex;
        width: 100%;
        min-height: 64px;
        justify-content: space-between;
        align-items: center;
        background-color: #f9faf9;
        margin: 0;
        padding: 0px 20px;

        .score-details {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 800;
            color: rgba($heavymetal, 0.8);
            cursor: pointer;
            text-align: right;
        }

        .score-group {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 900;

            .score-in-words {
                font-size: 14px;
                font-weight: 700;
                margin-left: 10px;
            }
        }
    }
}

.modalText {
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
}

.mobileOnly {
    display: none;
    @media (max-width: 992px) {
        display: initial;
    }
}

.desktopOnly {
    display: initial;
    @media (max-width: 992px) {
        display: none;
    }
}

.desktopOnly--carousel {
    display: flex;
    @media (max-width: 992px) {
        display: none;
    }
}

.mobile-email {
    display: flex;
    flex-direction: column;

    .mobile-email-header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;

        .mobile-email-left-side {
            display: flex;
            align-items: flex-end;
        }

        .mobile-email-header-details {
            display: flex;
            flex-direction: column;
            margin-left: 5px;

            .mobile-email-from {
                font-size: 14px;
                font-weight: 600;
            }

            .mobile-email-time {
                font-size: 12px;
                font-weight: 400;
                color: rgba($heavymetal, 0.6);
                margin: 0 5px;
            }

            .mobile-email-icons {
                margin-left: auto !important;
                background-color: pink !important;
            }
        }
    }
}
