.help-questionmark {
    height: 20px;
    width: 20px;
    text-align: center;
    color: white;
    position: absolute;
    background-image: url('/static/img/icon-question.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 17px auto;
    margin-left: 7px;
    margin-right: 7px;
}

.help-language {
    border-radius: 50%;
    height: 20px;
    width: 20px;
    text-align: center;
    color: white;
    display: flex;
    position: absolute;
    background-image: url('/static/img/icon-attention.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 17px auto;
    margin-left: 4px;
    margin-right: 4px;
    top: 10px;
    right: 10px;
    z-index: 1000;
}

.popover {
    padding: 10px 20px;
    letter-spacing: 0.02em;
    border: none;
    box-shadow: 3px 4px 30px -8px rgba(59, 55, 55, 0.18);

    .bs-popover-bottom .arrow::before,
    .arrow::before {
        border-bottom-color: transparent;
    }

    .popover-body {
        color: lighten($heavymetal, 20);
        line-height: 1.6em;
    }
}
