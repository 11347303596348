.spinner {
    text-align: center;
    display: inline-block;
    width: 100%;
}

.spinner-table {
    &.small {
        .spinner {
            margin: 0;
            padding: 0;

            svg {
                border-radius: 50%;
                padding: 8px;
                width: 40px;
                opacity: 0.6;
                height: 40px;
            }
        }
    }
}

.spinner svg {
    border-radius: 50%;
    padding: 8px;
}

.qp-circular-loader {
    width: 64px;
    /* 2*RADIUS + STROKEWIDTH */

    height: 64px;
    /* 2*RADIUS + STROKEWIDTH */

    stroke-width: 3px;
}

.qp-circular-loader-path {
    stroke-dasharray: 58.9;
    /* 2*RADIUS*PI * ARCSIZE/360 */

    stroke-dashoffset: 58.9;
    /* 2*RADIUS*PI * ARCSIZE/360 */
    /* hides things initially */
}

/* SVG elements seem to have a different default origin */

.qp-circular-loader,
.qp-circular-loader * {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

/* Rotating the whole thing */

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.qp-circular-loader {
    -webkit-animation-name: rotate;
    animation-name: rotate;
    -webkit-animation-duration: 1568.63ms;
    animation-duration: 1568.63ms;
    /* 360 * ARCTIME / (ARCSTARTROT + (360-ARCSIZE)) */

    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

/* Filling and unfilling the arc */

@-webkit-keyframes fillunfill {
    from {
        stroke-dashoffset: 58.8;
        /* 2*RADIUS*PI * ARCSIZE/360 - 0.1 */
        /* 0.1 a bit of a magic constant here */
    }
    50% {
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: -58.4;
        /* -(2*RADIUS*PI * ARCSIZE/360 - 0.5) */
        /* 0.5 a bit of a magic constant here */
    }
}

@keyframes fillunfill {
    from {
        stroke-dashoffset: 58.8;
        /* 2*RADIUS*PI * ARCSIZE/360 - 0.1 */
        /* 0.1 a bit of a magic constant here */
    }
    50% {
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: -58.4;
        /* -(2*RADIUS*PI * ARCSIZE/360 - 0.5) */
        /* 0.5 a bit of a magic constant here */
    }
}

@-webkit-keyframes rot {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(-360deg);
    }
}

@keyframes rot {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

@-webkit-keyframes colors {
    0% {
        stroke: $shiraz;
    }
    20% {
        stroke: $heavymetal;
    }
    40% {
        stroke: darken($desertstorm, 10);
    }
    60% {
        stroke: $aspargus;
    }
    80% {
        stroke: $shiraz;
    }
    100% {
        stroke: $heavymetal;
    }
}

@keyframes colors {
    0% {
        stroke: $shiraz;
    }
    20% {
        stroke: $heavymetal;
    }
    40% {
        stroke: darken($desertstorm, 10);
    }
    60% {
        stroke: $aspargus;
    }
    80% {
        stroke: $shiraz;
    }
    100% {
        stroke: $heavymetal;
    }
}

.qp-circular-loader-path {
    -webkit-animation-name: fillunfill, rot, colors;
    animation-name: fillunfill, rot, colors;
    -webkit-animation-duration: 1333ms, 5332ms, 5332ms;
    animation-duration: 1333ms, 5332ms, 5332ms;
    /* ARCTIME, 4*ARCTIME, 4*ARCTIME */

    -webkit-animation-iteration-count: infinite, infinite, infinite;
    animation-iteration-count: infinite, infinite, infinite;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1), steps(4),
        linear;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1), steps(4), linear;
    -webkit-animation-play-state: running, running, running;
    animation-play-state: running, running, running;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}
